import axios from "axios";

//TODO: always prepare api from Api.handler.{functionName}()
// const endPointUrl = "http://2.2.2.103:8033";


const endPointUrl = "https://napi.ism.ly";
// const endPointUrl = "http://10.2.2.3:8033";

const Api = {
  // endPointUrl: "https://ismschoool.com/efortshub",
  endpoints: {
    //post
    login: (type: string) => endPointUrl + "/auth/" + type,
    //get
    validate: (token: string) => endPointUrl + "/auth/validate/admin/" + token,
    campus: {
      //post
      createCampus: endPointUrl + "/admin/add-campus",
      getAllCampus(token: string) {
        return endPointUrl + "/admin/all-campus/" + token;
      },
    },
  },
  handler: {
    login(email: string, password: string, token: string, type: string) {
      return axios.post(Api.endpoints.login(type), {
        email: email,
        password: password,
        token: token,
      });
    },
    validate(loginToken: string) {
      return axios.get(Api.endpoints.validate(loginToken));
    },
    campus: {
      createCampus(
        name: string,
        description: string | null = "",
        email: string,
        phone:string,
        password: string,
        campusAdminName: string | null = "",
        token: string
      ) {
        return axios.post(Api.endpoints.campus.createCampus, {
          name: name,
          description: description,
          ca_name: campusAdminName,
          email: email,
          phone:phone,
          password: password,
          token: token,
        });
      },
      getAllCampus(token:string) {
        return axios.get(Api.endpoints.campus.getAllCampus(token));
      },
    },
  },
};

export default Api;
