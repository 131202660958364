import { Person, SchoolTwoTone } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { load } from "react-cookies";
import LoadingView from "../../components/loading-view";
import Api from "../../utils/Api";
import { ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function FgAddCampus() {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [campusName, setCampusName] = useState("");
  const [caName, setCaName] = useState("");
  const [caEmail, setCaEmail] = useState("");
  const [caPhone, setCaPhone] = useState("");
  const [caPassword, setCaPassword] = useState("");
  const [caVerifyPassword, setCaVerifyPassword] = useState("");
  const [campusNameError, setCampusNameError] = useState(false);
  const [caEmailError, setCaEmailError] = useState(false);
  const [caPhoneError, setCaPhoneError] = useState(false);
  const [caPasswordError, setCaPasswordError] = useState(false);
  const [caVerifyPasswordError, setCaVerifyPasswordError] = useState(false);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    handleCreateCampus() {
      setCampusNameError(false);
      setCaEmailError(false);
      setCaPasswordError(false);
      setCaVerifyPasswordError(false);
      if (!campusName || campusName.length === 0) {
        // alert("Campus name is required");
        setCampusNameError(true);
        return;
      }
      const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!caEmail || !emailTester.test(String(caEmail).toLowerCase())) {
        setCaEmailError(true);
        return;
      }
      if (!caPhone || caPhone.length <= 7) {
        setCaPhoneError(true);
        return;
      }
      if (!caPassword || caPassword.length <= 7) {
        setCaPasswordError(true);
        alert("password length must be greater than 8");
        return;
      }
      if (!caVerifyPassword || caVerifyPassword !== caPassword) {
        setCaVerifyPasswordError(true);
        return;
      }
      functionHandler.setInfo(null, "Adding campus details. Please wait...");
      setIsLoading(true);
      Api.handler.campus
        .createCampus(
          campusName,
          description,
          caEmail,
          caPhone,
          caPassword,
          caName,
          loginToken
        )
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          if (apiResponse.status === "success") {
            functionHandler.setInfo(true, "Campus has been added successfully");
            setCaName("");
            setDescription("");
            setCampusName("");
            setCaEmail("");
            setCaPassword("");
            setCaVerifyPassword("");
          } else {
            functionHandler.setInfo(false, apiResponse.message);
          }
        })
        .catch((e) => {
          console.log("error on creating campus", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading && <LoadingView />}
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.2,
          pt: 2,
          pb: 8,
          maxWidth: 560,
          // backgroundColor:'red',
          padding: 4,
          top: 0,
          mt:8,
        }}
      >
        <Typography
          variant="body2"
          color={Constants.colors.lightTheme.palette.secondary.main}
        >
          Adding campus also require adding an Administrator for that campus!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
          }}
        >
          <SchoolTwoTone sx={{ height: 32, width: 32 }} />
          <Typography>Campus details</Typography>
        </Box>
        <TextField
          variant="standard"
          required
          label="Campus name"
          value={campusName}
          error={campusNameError}
          onChange={(event) => {
            setCampusName(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          multiline
          label="Description"
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
          }}
        >
          <Person sx={{ height: 32, width: 32 }} />
          <Typography
            variant="subtitle2"
            color={Constants.colors.lightTheme.palette.primary.main}
          >
            Campus Administrator's credential * :
          </Typography>
        </Box>

        <TextField
          variant="standard"
          label="Name"
          value={caName}
          type="text"
          onChange={(event) => {
            setCaName(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          required
          label="Email"
          value={caEmail}
          type="email"
          error={caEmailError}
          onChange={(event) => {
            setCaEmail(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          required
          label="Phone"
          value={caPhone}
          type="tel"
          error={caPhoneError}
          onChange={(event) => {
            setCaPhone(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          type="password"
          required
          label="Password"
          value={caPassword}
          error={caPasswordError}
          onChange={(event) => {
            setCaPassword(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          type="password"
          required
          label="Verify password"
          value={caVerifyPassword}
          error={caVerifyPasswordError}
          onChange={(event) => {
            setCaVerifyPassword(event.target.value);
          }}
        />

        <Button
          sx={{ mt: 4 }}
          variant="contained"
          onClick={() => {
            functionHandler.handleCreateCampus();
          }}
        >
          Create campus
        </Button>
      </Paper>

      <Snackbar
        open={info?.visible}
        autoHideDuration={6000}
        onClose={functionHandler.handleSnackbarClose}
      >
        <Alert
          onClose={functionHandler.handleSnackbarClose}
          severity={
            info?.status === true
              ? "success"
              : info?.status === false
              ? "error"
              : "info"
          }
          sx={{ width: "100%" }}
        >
          {info?.message || "!!!"}
        </Alert>
      </Snackbar>
    </Box>
  );
}
