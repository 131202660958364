import {
  AddLocationTwoTone,
  Edit,
  School,
  Settings,
} from "@mui/icons-material";
import { Box, Container, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { load } from "react-cookies";
import { useNavigate } from "react-router-dom";
import AccountActionTooltip from "../components/dashboard/account-action-tooltip";
import DashboardDrawer from "../components/dashboard/drawer";
import HeaderBar from "../components/header-bar";
import LoadingView from "../components/loading-view";
import FgAddCampus from "../fragments/dashboard/fg-add-campus-dashboard";
import Api from "../utils/Api";
import { ResponseType, UsersDataType } from "../utils/ConstType";
import Constants from "../utils/Constants";
import FragmentAllCampus from "../fragments/dashboard/fg-all-campus-dashboard";
//cookie.load("login-type");

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [me, setMe] = useState<UsersDataType.AdminType>({
    name: "N/A",
    email: "N/A",
  });
  const [activeFragment, setActiveFragment] = useState<
    "all-campus" | "add-campus" | "edit-account" | "settings"
  >("all-campus");
  const [title, setTitle] = useState("Dashboard");

  const drawerItem = [
    {
      groupTitle: "Campus area",
      items: [
        {
          title: "All campuses",
          onClick: () => {
            setActiveFragment("all-campus");
          },
          iconComponent: <School />,
        },
        {
          title: "Add campus",
          onClick: () => {
            setActiveFragment("add-campus");
          },
          iconComponent: <AddLocationTwoTone />,
        },
      ],
    },
    {
      groupTitle: "Account",
      items: [
        {
          title: "Edit account",
          onClick: () => {
            setActiveFragment("edit-account");
          },
          iconComponent: <Edit />,
        },
        {
          title: "Settings",
          onClick: () => {
            setActiveFragment("settings");
          },
          iconComponent: <Settings />,
        },
      ],
    },
  ];

  const navigate = useNavigate();
  React.useEffect(() => {
    const loginToken = load(Constants.key.CookiesKey.loginToken);

    // alert(loginToken);

    if (loginToken) {
      Api.handler
        .validate(loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;

          if (apiResponse.status === "success") {
            console.log("valid token: ", apiResponse);
            setMe(apiResponse.message);
            setIsLoading(false);
          } else {
            alert("Error while logging in.");
            navigate(Constants.navigationStack.login);
          }

          // if (response.data) {
          //   setIsLoading(false);
          // } else {
          // }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      navigate(Constants.navigationStack.login);
    }
  }, []);

  return (
    <Box>
      {isLoading && <LoadingView />}
      <HeaderBar
        title={title}
        onMenuClick={() => {
          setIsDrawerOpen((o) => !o);
        }}
        onUserNameClick={() => setShowTooltip(true)}
        userName={me.name}
      />
      <DashboardDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        drawerItem={drawerItem}
        setTitle={setTitle}
      />
      {showTooltip && (
        <AccountActionTooltip user={me} onHide={() => setShowTooltip(false)} />
      )}
      <Container>
        {activeFragment === "all-campus" ? (
          <FragmentAllCampus />
        ) : activeFragment === "add-campus" ? (
          <FgAddCampus />
        ) : activeFragment === "edit-account" ? (
          <Box>edit account</Box>
        ) : (
          activeFragment === "settings" && <Box> settings</Box>
        )}
      </Container>
    </Box>
  );
};

export default Dashboard;
