import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function HbDialog({
  open,
  onClose,
  title = "Information !",
  message = "",
  action1 = {
    text: "Close",
    onClick: () => onClose(),
  },
  action2,
}: {
  open: boolean;
  title?: string;
  onClose: () => void;
  message: string;
  action1?: {
    text: string;
    onClick: () => void;
  };
  action2?: {
    text: string;
    onClick: () => void;
  };
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 200 }}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {action2 && (
          <Button autoFocus onClick={action2.onClick}>
            {action2.text}
          </Button>
        )}
        {action1 && (
          <Button autoFocus onClick={action1.onClick}>
            {action1.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
