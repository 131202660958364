import { MenuOpenOutlined } from "@mui/icons-material";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import Constants from "../../utils/Constants";

export default function DashboardDrawer({
  open,
  onClose,
  drawerItem,
  setTitle,
}: {
  open: boolean;
  onClose: () => void;
  setTitle: (title: string) => void;
  drawerItem: {
    groupTitle: string;
    items: {
      title: string;
      onClick: () => void;

      iconComponent: JSX.Element;
    }[];
  }[];
}) {
  return (
    <Drawer open={open} onClose={onClose}>
      <Box sx={{ minWidth: 200, overflow: "hidden" ,
        width:'100%',
        maxWidth:450,}}>
        <Toolbar
          variant="regular"
          sx={{
            backgroundColor: Constants.colors.lightTheme.palette.primary.main,

            boxShadow: "0px 1px 5px 3px rgba(0, 0, 0, 0.38)",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            sx={{ mr: 2 }}
            onClick={onClose}
          >
            <MenuOpenOutlined
              sx={{
                color: Constants.colors.lightTheme.palette.text.onPrimary,
              }}
            />
          </IconButton>
        </Toolbar>
        {drawerItem.map((d) => (
          <Box key={d.groupTitle}>
            {d.groupTitle && (
              <Typography sx={{ paddingTop: 1, paddingLeft: 2 }}>
                {d.groupTitle}
              </Typography>
            )}
            <List>
              {d.items.map((item) => (
                <ListItem disablePadding key={item.title}>
                  <ListItemButton
                    onClick={() => {
                      onClose();
                      setTitle(item.title);
                      item.onClick();
                    }}
                  >
                    <ListItemIcon>{item.iconComponent}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </Drawer>
  );
}
