import { DeleteForever } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
export default function DialogDeleteCampus({
  campus,
  onClose = () => {},
  onDeleted = () => {},
}: {
  campus: HbType.CampusDataType;
  onClose: () => void;
  onDeleted: () => void;
}) {
  const [password, setPassword] = useState("");
  const [id, setId] = useState<number | null>(null);
  const [error, setError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (password && id) {
      console.log("attempting to delete campus by id: ", id);

      onDeleted();
    }
  }, [password, id]);

  return (
    <Dialog maxWidth="sm" fullWidth  open={true} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
          gap: 2,
          width: isMobile ? "100%" : undefined,
          fill: "Menu",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: Constants.colors.lightTheme.palette.error.main,
          }}
        >
          <DeleteForever />
        </Avatar>

        <Typography>
          Are you sure? you want to delete following campus:
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            color={Constants.colors.lightTheme.palette.primary.main}
            fontWeight="bold"
          >
            Name:
          </Typography>
          <Typography
            color={Constants.colors.lightTheme.palette.secondary.main}
          >
            {campus.name}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            color={Constants.colors.lightTheme.palette.primary.main}
            fontWeight="bold"
          >
            Description:
          </Typography>
          <Typography
            color={Constants.colors.lightTheme.palette.secondary.main}
          >
            {campus.description}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            color={Constants.colors.lightTheme.palette.primary.main}
            fontWeight="bold"
          >
            Admin name:
          </Typography>
          <Typography
            color={Constants.colors.lightTheme.palette.secondary.main}
          >
            {campus.ca_name}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            color={Constants.colors.lightTheme.palette.primary.main}
            fontWeight="bold"
          >
            Admin email:
          </Typography>
          <Typography
            color={Constants.colors.lightTheme.palette.secondary.main}
          >
            {campus.email}
          </Typography>
        </Box>

        <TextField
          required
          error={!!error}
          sx={{ width: "100%", fontWeight: "bold" }}
          variant="outlined"
          label="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (!password) {
                setError("Please enter your password");
              } else {
                setId(campus.id);
              }
            }}
          >
            Delete
          </Button>
          <Button variant="contained" color="warning" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
