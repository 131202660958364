import { Box, List, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import DialogDeleteCampus from "../../components/dashboard/campus-delete-dialog";
import DialogEditCampus from "../../components/dashboard/campus-edit-dialog";
import CampusListItem from "../../components/dashboard/campus-list-item";
import HbDialog from "../../components/dialog";
import LoadingView from "../../components/loading-view";
import Api from "../../utils/Api";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import { Warning } from "@mui/icons-material";

export default function FragmentAllCampus() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [campuses, setCampuses] = useState<HbType.CampusDataType[]>([]);
  const [campusInEditMode, setCampusInEditMode] =
    useState<HbType.CampusDataType | null>(null);
  const [campusInDeleteMode, setCampusInDeleteMode] =
    useState<HbType.CampusDataType | null>(null);
  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const demoData = [
    {
      id: 1,
      name: "x",
      description: "x",
      email: "x",
      ca_name: "x",
    },
    {
      id: 2,
      name: "name",
      description: "description",
      email: "email",
      ca_name: "campusAdminName",
    },
    {
      id: 4,
      name: "Campus A",
      description: "",
      email: "a@a.com",
      ca_name: "A",
    },
    {
      id: 10,
      name: "Campus b",
      description: "fds",
      email: "a@a.com",
      ca_name: "a",
    },
    {
      id: 14,
      name: "Campus c",
      description: "fds",
      email: "a@a.com",
      ca_name: "a",
    },
    {
      id: 15,
      name: "aaaaaaaa",
      description: "",
      email: "aa@aa.com",
      ca_name: "aaaaaaaa",
    },
  ];

  useEffect(() => {
    functionHandler.getAllCampus();
  }, []);

  const functionHandler = {
    getAllCampus() {
      setIsLoading(true);
      Api.handler.campus
        .getAllCampus(loginToken)
        .then((res) => {
          // console.log("response is : ", res.data);
          setCampuses(res.data as any);
        })
        .catch((reason) => {
          console.log(reason);
          setError(reason.message);
        })
        .finally(() => setIsLoading(false));
    },
  };

  const MemoizedCampusList = React.memo(() => {
    console.log(" list rendered...");
    return (
      <List sx={{ width: "100%" }}>
        {campuses.map((c) => (
          <CampusListItem
            key={c.id}
            campusDetails={c}
            onDelete={() => {
              // alert("deleting " + c.name);
              setCampusInDeleteMode(c);
            }}
            onEdit={() => {
              // alert("editing: " + c.name);
              setCampusInEditMode(c);
            }}
          />
        ))}
      </List>
    );
  });

  return (
    <Box>
      {isLoading && <LoadingView />}
      <HbDialog
        open={error !== null}
        onClose={() => setError(null)}
        message={error || ""}
      />

      {campusInDeleteMode && (
        <DialogDeleteCampus
          campus={campusInDeleteMode}
          onClose={() => setCampusInDeleteMode(null)}
          onDeleted={() => {
            setCampuses((o) => o.filter(x => x.id !== campusInDeleteMode.id));
            setCampusInDeleteMode(null);
          }}
        />
      )}

      {campusInEditMode && (
        <DialogEditCampus
          campus={campusInEditMode}
          onClose={() => setCampusInEditMode(null)}
        />
      )}
      <Box
        sx={{
          mt: 2,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {campuses.length > 0 ? <MemoizedCampusList /> : <Box p={10} display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2} alignSelf="center" >

          <Warning />
          <Typography variant="h6">No Campus found !</Typography>
          <Typography>Please create campus from drawer menu</Typography>

        </Box>}
      </Box>
    </Box>
  );
}
