import { Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
export default function DialogEditCampus({
  campus,
  onClose = () => {},
}: {
  campus: HbType.CampusDataType;
  onClose: () => void;
}) {
  const [c, setCampus] = useState<HbType.CampusDataType | null>(null);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setCampus(campus);
  }, []);
  return (
    <Dialog maxWidth="sm" fullWidth  open={true} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
          gap: 2,
          width: isMobile ? "100%" : undefined,
          fill: "Menu",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: Constants.colors.lightTheme.palette.primary.main,
          }}
        >
          <Edit />
        </Avatar>
        <TextField
          sx={{ width: "100%", fontWeight: "bold" }}
          variant="outlined"
          label="Name"
          value={c?.name}
          onChange={(e) =>
            setCampus((o) => ({
              ...(o || {
                name: "",
                ca_name: "",
                description: "",
                email: "",
                phone: "",
                id: 0,
              }),
              name: e.target.value,
            }))
          }
        />
        <TextField
          sx={{ width: "100%", fontWeight: "bold", maxLines: 3 }}
          variant="outlined"
          label="Description"
          value={c?.description}
          onChange={(e) =>
            setCampus((o) => ({
              ...(o || {
                name: "",
                ca_name: "",
                description: "",
                email: "",
                phone: "",
                id: 0,
              }),
              description: e.target.value,
            }))
          }
        />
        <TextField
          sx={{ width: "100%", fontWeight: "bold" }}
          variant="outlined"
          label="Admin Name"
          value={c?.ca_name}
          onChange={(e) =>
            setCampus((o) => ({
              ...(o || {
                name: "",
                ca_name: "",
                description: "",
                email: "",
                phone: "",
                id: 0,
              }),
              ca_name: e.target.value,
            }))
          }
        />
        <TextField
          sx={{ width: "100%", fontWeight: "bold" }}
          variant="outlined"
          label="Admin Email"
          value={c?.email}
          onChange={(e) =>
            setCampus((o) => ({
              ...(o || {
                name: "",
                ca_name: "",
                description: "",
                email: "",
                phone: "",
                id: 0,
              }),
              email: e.target.value,
            }))
          }
        />
        <TextField
          sx={{ width: "100%", fontWeight: "bold" }}
          variant="outlined"
          label="Admin phone number"
          value={c?.phone}
          onChange={(e) =>
            setCampus((o) => ({
              ...(o || {
                name: "",
                ca_name: "",
                description: "",
                email: "",
                phone: "",
                id: 0,
              }),
              phone: e.target.value,
            }))
          }
        />
        <Divider />
        <Typography variant="subtitle2" color="GrayText">
          Optional* :- Change old password.
        </Typography>
        <TextField
          sx={{ width: "100%", fontWeight: "bold" }}
          variant="outlined"
          label="New password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          sx={{ width: "100%", fontWeight: "bold" }}
          variant="outlined"
          label="Verify new password"
          type="password"
          value={verifyPassword}
          onChange={(e) => setVerifyPassword(e.target.value)}
        />

        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button variant="contained" color="primary">
            Save
          </Button>
          <Button variant="contained" color="error" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
