/* eslint-disable no-useless-escape */
import LockIcon from "@mui/icons-material/Lock";
import {
  AppBar,
  Box,
  Button,
  Card,
  Container,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import { CSSProperties, useEffect, useState } from "react";
import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";

import { v4 as uuid } from "uuid";
import LoadingView from "../components/loading-view";
import Api from "../utils/Api";
import { ResponseType } from "../utils/ConstType";
import Constants from "../utils/Constants";

export default function LoginScreen() {
  const [goBack, setGoback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (goBack) {
      navigate(Constants.navigationStack.home);
    }
  }, [goBack]);

  const functionHandler = {
    loginHandler: (e: any) => {
      e.preventDefault();
      const form = e.target;
      const email = form.email.value;
      const password = form.password.value;
      // const remember = form.remember.checked;

      // password valiation
      // const oneWordUpperCase = /(?=.*[A-Z])/;
      const eightCharacter = /.{8}$/;
      // const oneDigit = /(?=.*[0-9])/;
      // email validation
      const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      if (!email || !isValidEmail.test(email)) {
        setIsPasswordError("invalid email");
      } else if (!eightCharacter.test(password)) {
        setIsPasswordError("password should be minimum 8 character");
        return;
      } else {
        // setIsPasswordError("login success");
        setIsLoading(true);

        const token = uuid();
        // alert(token);

        //save the token to session

        Api.handler
          .login(email, password, token, Constants.key.loginType.admin)
          .then((response) => {
            // Handle successful response
            setIsLoading(false);
            console.log(response.data);
            const apiResponse: ResponseType = response.data;
            if (apiResponse.status === Constants.key.responseType.success) {
              navigate(Constants.navigationStack.home);
              cookie.save(Constants.key.CookiesKey.loginToken, token, {
                maxAge: 3600 * 24 * 60,
              });
            } else {
              alert("Failed to login.Error: " + apiResponse.message);
            }
          })
          .catch((error) => {
            // Handle error response
            setIsLoading(false);
            console.error(error);
          });
      }
    },
  };

  const styles = {
    logginButton: {
      width: "100%",
      margin: "10px 0px",
    },
    forgotPassword: {
      cursor: "pointer",
      textTransform: "capitalize",
    },
    passwordError: {
      marginTop: "10px",
      color: "red",
      textTransform: "capitalize",
      fontWeight: "bold",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      minHeight: 600,
    },
    formInner: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 400,
      maxWidth: "90%",
      borderRadius: 4,
      padding: 4,
    },
    formInputWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "stretch",
      width: "100%",
    },
    lockIcon: {
      width: 72,
      height: 72,
      boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.38)",
      borderRadius: "50%",
      padding: 1.5,
      backgroundColor: "#ff974799",
      margin: 2,
    },
    textInput: {
      width: "100%",
      marginTop: 1,
    },
    rememberWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
    },
  } as Record<string, CSSProperties>;

  return (
    <>
      {isLoading && <LoadingView />}
      <div>
        <AppBar position="static">
          <Toolbar
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            <Typography
              sx={{ textTransform: "capitalize", fontWeight: "bold" }}
            >
              Login as {Constants.key.loginType.admin}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <form action="" onSubmit={functionHandler.loginHandler}>
            <Box sx={styles.formWrapper}>
              <Card sx={styles.formInner}>
                <LockIcon sx={styles.lockIcon} />

                <Box sx={styles.formInputWrapper}>
                  <TextField
                    sx={styles.textInput}
                    label="Email"
                    variant="outlined"
                    type="email"
                    name="email"
                  />
                  <TextField
                    sx={styles.textInput}
                    label="Password"
                    variant="outlined"
                    type="password"
                    name="password"
                  />
                  <Typography sx={styles.passwordError}>
                    {isPasswordError}
                  </Typography>
                  {/* <Box sx={styles.rememberWrapper}>
                    <Checkbox name="remember" />
                    <Typography>remember me</Typography>
                  </Box> */}
                  <Button
                    sx={styles.logginButton}
                    variant="contained"
                    type="submit"
                  >
                    sign in
                  </Button>
                  {/* <Button variant="text" sx={styles.forgotPassword}>
                    Forgot Password?
                  </Button>
                  <Button variant="outlined" sx={styles.logginButton}>
                    Create Account
                  </Button> */}
                </Box>
              </Card>
            </Box>
          </form>
        </Container>
      </div>
    </>
  );
}
